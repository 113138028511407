<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-card class="mt-24 mb-24 pb-0" style="background-color: #fff; margin-top: 0px;">
			
            <a-row :gutter="24" class="mb-0 pb-0">

                <!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-0 pb-0">
                    <a-select default-value="Tanzania, United Republic of" style="border: none !important; margin-top: 4px;" class="text-right" @change="handleChange">
                        
                        <a-select-option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                        </a-select-option>
                        <a-select-option value="Kenya">
                            Kenya
                        </a-select-option>
                    </a-select>
                </a-col> -->

                <a-col :span="24" :md="8" :lg="8" :xl="8" class="mb-0 pb-0">
                    
                    <a-form-item style="width: 100% !important;" class="mb-0 pb-0">
                        <a-input v-model="pagination.search" placeholder=" Search ..." class="w-full pb-0 mb-0" :bordered="false" style="width: 100%;">
                            <template #prefix>
                                <a-icon type="search" theme="outlined"> </a-icon>
                            </template>
                            <template #suffix v-if="pagination.search !== ''">
                                <a-button type="link" @click="cancelSearch" style="border: none;">
                                    <a-icon type="close" theme="outlined" />
                                </a-button>
                            </template>
                        </a-input>
                    </a-form-item>
                    
                </a-col>

                <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-0 pb-0">
                        <a-button
                            id="btnSearch"
                            :type="pagination.search.length > 0 ? 'primary' : ''"
                            @click="submitSearch"
                            :class="'mt-5'"
                            :disabled="pagination.search === ''">
                            <a-icon type="search" theme="outlined" />
                            Search
                        </a-button>
                    <!-- <a-button
                        id="btnSearch"
                        :disabled="true">
                        <a-icon type="filter" theme="outlined" />
                        Filter
                    </a-button> -->
                </a-col>

                <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                    <a-button
                        v-if="userDetails.role != null && userDetails.role.code == 'teacher'"
                        type="primary"
                        @click="onCreateLesson"
                        class="">
                        <a-icon type="plus" theme="outlined" />
                        Publish New Notes
                    </a-button>
                </a-col>

            </a-row>

        </a-card>

        <!-- <a-row :gutter="24" class="px-20">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
                <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
            </a-col>
        </a-row> -->

        <div v-if="!loadingData" class="mb-20 pt-20 mt-20">

            <a-row :gutter="24" class="px-20 mt-20">

                <a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24 pb-10" v-for="not in notes" :key="not.uuid">
                    <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15" @click="onViewFile(not)">
                        <!-- <a class="text-dark p-0 m-0" style="text-decoration: none !important;" > -->
                            <a-row :gutter="24">
                                <a-col :span="24">
                                    <div class="icon">
                                        <img id="classImg" width="100%" height="200px" :src="not.thumbnail != null ? not.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row :gutter="24" class="px-20">
                                <a-col :span="20">
                                    <h6 class="mt-10 pt-4 pl-2">{{ not.title.length > 20 ? `${not.title.substring(0, 20)}...` : not.title }}</h6>
                                </a-col>

                                <a-col :span="4">
                                    <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                    </svg>
                                </a-col>

                                
                            </a-row>

                            <a-row :gutter="24" class="px-20">
                                <a-col :span="24" :md="16" :lg="16" :xl="16">
                                    <!-- <a-avatar :src="not.creator != null && not.creator.profilePicUrl ? not.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                    
                                    <a-row :gutter="24" class="">
                                        <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            By {{ not.creator != null ? not.creator.firstName.length + not.creator.lastName.length > 12 ? `${formatName(not.creator.firstName)} .${not.creator.lastName[0].toUpperCase()}` : `${formatName(not.creator.firstName)} ${formatName(not.creator.lastName)}` : 'Unknown'}}, Teacher
                                        </a-col>
                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                            Read: {{ not.viewCount }} Times
                                        </a-col>
                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-5">
                                            <svg v-for="(option, index) in 4" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                            </svg>
                                            <svg class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                            </svg>
                                        </a-col>
                                    </a-row>

                                </a-col>
                                <a-col :span="24" :md="8" :lg="8" :xl="8" class="text-right">
                                    <h6 class="text-silabu pt-10">{{ not.price != null && not.price != 0 ? `${not.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row>

                            <!-- <a-row :gutter="24" class="mt-10 px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                    <span><strong>{{ not.slots + 1 - not.membersCount }}</strong></span> <small>Slots remain </small>
                                </a-col>

                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pr-5">{{ not.price != null && not.price != 0 ? `${not.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row> -->
                        <!-- </a> -->
                    </a-card>
                </a-col>

            </a-row>

        </div>

        <div v-if="loadingData">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                    <a-spin class="text-primary" size="large"/>

                </a-col>

            </a-row>
        </div>

        <a-modal v-model="filter.showModal" :footer="null">
            
            <a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select all options that apply to you</h6>
                </a-col>
            </a-row>

            <!-- <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'">Classes</a-radio>
                        <a-radio :value="'notes'">Notes</a-radio>
                        <a-radio :value="'quizzes'">Quizzes</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row> -->

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'">Starting Today</a-radio>
                        <a-radio :value="'week'">Starting Next week</a-radio>
                        <a-radio :value="'weeks'">Starting in 2 weeks</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutters="24" class="mt-20 pt-20">
                <a-col :span="24" class="text-right">
                    <a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
                        Clear all filters
                    </a-button>
                    &nbsp;&nbsp;
                    <a-button type="primary" class="text-white txtBtn" style="" @click="submitFilter">
                        Appy filters
                    </a-button>
                </a-col>
            </a-row>
            
        </a-modal>


        <!-- LESSON MODAL -->
		<a-modal v-model="lesson.showModal" :footer="null">

			<h4 class="text-center">{{  lesson.isCreate ? 'Add Notes' : 'Edit Notes'}}</h4>

			<a-form
				:form="lessonForm"
				class="login-form"
				@submit="handleSubmitFile"
				:hideRequiredMark="true">


                <a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
                    <a-col :span="24" class="my-0 py-0 text-center">
                        
                        <a-button id="classIconBtn" @click="onSelectImage">
                            <img id="previewClassIcon" style="margin: 0px; width: 75px; height: 75px;" v-if="lesson.thumbnail != null" :src="`${lesson.thumbnail}`"/>
                            <span v-if="lesson.thumbnail == null">
                                <svg class="dark:text-white mb-5" style="width: 22px; color: #444444" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M4 18V8c0-.6.4-1 1-1h1.5l1.7-1.7c.2-.2.4-.3.7-.3h6.2c.3 0 .5.1.7.3L17.5 7H19c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H5a1 1 0 0 1-1-1Z"/>
                                    <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                </svg><br/>
                                Add Photo
                            </span>
                        </a-button>

                    </a-col>

                    <a-col :span="24" class="my-0 py-0 mt-10 text-center">
                        <small>You can either upload a photo or use one of the avatars</small>
                    </a-col>
                </a-row>


                <a-form-item class="mb-10" label="Grade" :colon="false">
                    <a-select 
                        v-decorator="['gradeUuid', { rules: [{ required: true, message: 'Please select targeted grade!' }] },]" 
                        style="width: 100%;" 
                        placeholder="Select Grade"
                        @change="onSelectGrade">
                        <a-select-option v-for="option in grades"
                            :value="option.uuid"
                            :key="option.name">
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
				</a-form-item>

                <a-form-item class="mb-10" label="Subject" :colon="false">
                    <a-select 
                        v-decorator="['subjectUuid', { rules: [{ required: true, message: 'Please select subject!' }] },]" 
                        style="width: 100%;" 
                        placeholder="Select Subject"
                        @change="onSelectGrade">
                        <a-select-option v-for="option in subjects"
                            :value="option.uuid"
                            :key="option.name">
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
				</a-form-item>

				<a-form-item class="mb-10" label="Title" :colon="false">
					<a-input 
						class="mt-0"
						v-decorator="['title', { rules: [{ required: true, message: 'Please input your title of the topic!' }] },]" 
						placeholder="Title"/>
				</a-form-item>

				<a-form-item class="mb-10" label="File" :colon="false" v-if="lesson.isCreate">
					<a-input 
						type="file"
						ref="file"
						v-decorator="['file',
							{ rules: [{ required: true, message: 'Please upload file!' }] },
						]" placeholder="File" 
						@change="handleFileChange"/>
				</a-form-item>

                <a-row :gutter="24" class="mt-20 pt-10 mb-10 pb-0">

                    <a-col :span="18" class="my-0 py-0" v-if="!lesson.isPaid">
                        <label><strong>This class is free</strong></label><br/>
                        <small>Students can join and access content free</small>
                    </a-col>

                    <a-col :span="18" class="my-0 py-0" v-if="lesson.isPaid">
                        <label><strong>This class is paid</strong></label><br/>
                        <small>Students pay the set price to join this class</small>
                    </a-col>

                    <a-col :span="6" class="my-0 py-0 text-right">
                        <a-switch class="mt-10 pt-10" v-model="lesson.isPaid" @change="onPriceChange($event)"/>
                    </a-col>
                </a-row>

                <a-form-item class="mb-10" label="Price" :colon="false" v-if="lesson.isPaid">
					<a-input 
						class="mt-0"
                        type="number"
						v-decorator="['price', { rules: [{ required: true, message: 'Please enter the price!' }] },]" 
						placeholder="Price"/>
				</a-form-item>

				<a-form-item class="mt-20">
					<a-button type="primary" :loading="lesson.loading" html-type="submit" class="login-form-button text-white">
						{{ lesson.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>


        <!-- AVATAR MODAL -->
		<a-modal v-model="lesson.avatarShowModal" :footer="null" :width="1080">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select Avatar</h6>
				</a-col>
			</a-row>

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0" v-if="!lesson.avatarLoading">
				<a-col :span="6" class="" v-for="option in avatars" :key="option.uuid">
                    <img @click="onSelectedImage(option)" id="classImg" width="100%" height="150px" :src="option.icon" alt="">
                </a-col>
			</a-row>


			<div v-if="lesson.avatarLoading">
				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">
					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
						<a-spin class="text-primary" />
					</a-col>
				</a-row>
			</div>


		</a-modal>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				notes: [],
                grades: [],
                subjects: [],
                educationLevels: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 12,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },


                avatars: [],
				lessonForm: this.$form.createForm(this, { name: 'lesson_form' }),

                userDetails: {},

                lesson: {
					uuid: null,
					title: null,
					position: null,
                    levelUuid: undefined,
                    gradeUuid: undefined,
                    subjectUuid: undefined,
					description: null,
                    isPaid: false,
					topic: this.$route.params.topicUuid,
					isSubtopic: true,
					isCompleted: false,
					file: null,
					thumbnail: null,
                    avatarLoading: false,
                    avatarShowModal: false,
                    loading: null,
					isCreate: true,
					showModal: false,
				},
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getNotes();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getNotes()
                },
            },
            deep: true,
        },
		created() {
            this.initializeFilter();
			this.getNotes()
		},
		methods: {

            async onViewFile(item) {

                await localStorage.setItem("notesUuid", item.uuid);
				
                if((item.content.path != null && item.content.path.includes('.pdf')) || (item.thumbnail != null && item.thumbnail.includes('.pdf'))) {
                    await localStorage.setItem("pdfFilePath", item.content.path)
                    await localStorage.setItem("pdfFileName", item.thumbnail)

                    window.open(`/pdf-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.doc')) || (item.thumbnail != null && item.thumbnail.includes('.doc'))) {
                    await localStorage.setItem("docFilePath", item.content.path)
                    await localStorage.setItem("docFileName", item.thumbnail)

                    window.open(`/doc-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.mkv')) || (item.content.path != null && item.content.path.includes('.mp4'))){
                    await localStorage.setItem("videoFilePath", item.content.path)
                    await localStorage.setItem("videoFileName", item.thumbnail)

                    window.open(`/video-player`, '_blank');
                }
                else{
                    console.log(item.content)

                    this.notify('File format currently is not supported', 'error')
                }
				// this.$router.push(`/pdf-viewer`);
			},

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch() {
				this.pagination.currentPage = 1;
				this.getNotes();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getNotes();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getNotes();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getNotes();
            },


            async initializeFilter() {
                let filter = JSON.stringify(await localStorage.getItem('filter'))

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', JSON.stringify(filter))
                        
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

			async getNotes() {

                const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {

                    let userDetails = JSON.parse(userInfo);

                    this.userDetails = userDetails;

                    this.loadingData = true;

                    const { currentPage, perPage, search } = this.pagination;

                    let url = '';
                    if(search != null && search.length > 0) {
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/materials?creator=${userDetails.uuid}&search=${search}&filter=true&price=0>=<=${this.filter.price}&page=${currentPage}&size=${perPage}`;
                        }else{
                            url = `${this.$BACKEND_URL}/materials?creator=${userDetails.uuid}&search=${search}&page=${currentPage}&size=${perPage}`;
                        }
                    }else{
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/materials?creator=${userDetails.uuid}&filter=true&price=0><${this.filter.price}&page=${currentPage}&size=${perPage}`;
                        }else{
                            url = `${this.$BACKEND_URL}/materials?creator=${userDetails.uuid}&page=${currentPage}&size=${perPage}`;
                        }
                    }

                    

                    this.$AXIOS.get(url).then(async(response) => {
                        if (response.status == 200) {
                            this.notes = response.data.data;
                            this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                            this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                            this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                            this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                        }
                        
                        this.loadingData = false;
                        
                    }).catch(async(error) => {
                        
                        this.loadingData = false;

                        if(error.response && error.response.status == 401) {
                            await localStorage.setItem("user_token", null);
                            await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                            this.$router.push(`/sign-in`);
                        }
                    });
                }
            },


            async getGrades(levelUuid) {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/grades?educationLevel=${levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.grades = response.data.data;

				}).catch(error => {

		
					this.notify(error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!", 'error');
				
				})
			},


			async getSubjects(levelUuid) {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/subjects?educationLevels=${levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.subjects = response.data.data;

				}).catch(error => {
		
					this.notify(error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!", 'error');
				
				})
			},


            async getAvatars(subjectUuid) {

				this.lesson.avatarLoading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/avatars?subjects=${subjectUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.avatars = response.data.data;

					this.lesson.avatarLoading = false;

				}).catch(error => {

					this.lesson.avatarLoading = false;
		
					this.notify(error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!", 'error');
				
				})
			},


            onSelectImage() {
                this.lesson.avatarLoading = true;

                this.lesson.avatarShowModal = true;

				setTimeout(() => this.lesson.avatarLoading = false, 2000);
            },

            onCreateLesson() {
                this.$router.push(`/my-classes/publish-new-notes`)
			},


            onSelectGrade(uuid) {
				this.lesson.gradeUuid = uuid;
			},

            onSelectSubject(uuid) {
				this.lesson.subjectUuid = uuid;

                this.getAvatars(uuid);
			},


            onSelectedImage(avatar) {
				this.lesson.thumbnail = avatar.iconPath;
                this.lesson.avatarShowModal = false;
            },

            handleFileChange(event) {
				this.lesson.file = event.target.files[0];
				this.lesson.thumbnail = event.target.files[0].name;
			},


            onPriceChange(event) {
				if(!event) {
                    this.lessonForm.setFieldsValue({
						price: 0,
					});
				}
			},


			async handleSubmitFile(e) {
				e.preventDefault()
				if(this.lesson.isCreate) {

					this.lesson.loading = true

					let token = await localStorage.getItem("user_token")

					const config = {
						headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': `Bearer ${token}`,
						},
					};

					const formData = new FormData(); 

					formData.append(`files`, this.lesson.file, this.lesson.thumbnail); 

					let url = `${this.$BACKEND_URL}/uploads`;
					
					this.$AXIOS.post(url, formData, config).then(async(response) => {
						if (response.status >= 200 && response.status < 210) {

							this.handleSubmitLesson(response.data.data[0])
						}
					}).catch((err) => {

						this.lesson.loading = false;
						
						this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');
						
					});

				}else{
					this.handleSubmitLesson(null);
				}
			},


			handleSubmitLesson(attachment) {

				this.lessonForm.validateFields(async (err, values) => {
					
					if ( !err ) {

						this.lesson.loading = true;

						if(this.lesson.isCreate) {

							let payload = values;

							let { path, mimetype, size } = attachment

							payload.clazz = this.$route.params.uuid;
							payload.topic = this.$route.params.topicUuid;
							payload.isSubtopic = true;
							payload.attachment = { path, mimetype, size };

							let url = `${this.$BACKEND_URL}/topics`;

							this.$AXIOS.post(url, payload).then(async(response) => {
								
								this.lesson.uuid = null,
								this.lesson.title = null,
								this.lesson.position = null,
								this.lesson.description = null,
								this.lesson.isCompleted = false,
								this.lesson.isCreate = true,
								this.lesson.showModal = false,

								this.lessonForm.resetFields()

								this.getLessons()

								this.notify('Lesson has been saved successfully', 'success')

								this.lesson.loading = false;

							}).catch(async(error) => {
								
								this.lesson.loading = false;

								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{

							let payload = values;

							this.lesson.loading = true;

							let url = `${this.$BACKEND_URL}/topics/${this.lesson.uuid}`;

							this.$AXIOS.patch(url, payload).then(async(response) => {
								
								this.lesson.uuid = null,
								this.lesson.title = null,
								this.lesson.position = null,
								this.lesson.description = null,
								this.lesson.isCompleted = false,
								this.lesson.isCreate = true,
								this.lesson.showModal = false,

								this.lessonForm.resetFields()

								this.getLessons()

								this.notify('Lesson has been updated successfully', 'success')

								this.lesson.loading = false;

							}).catch(async(error) => {
								
								this.lesson.loading = false;
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}
					}else {

						this.lesson.loading = true;
					}
				})
			},


            notify(message, type) {
				notification[type]({
					message: message,
					description: '',
					placement: 'topRight',
					duration: 3,
				});
			},

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>